import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';
import user from '../services/session/reducer';
import isAuthenticated from './reducers/isAuthenticated';
import { ARTICLES_RK, ArticlesReducer } from '../modules/Catalog/Article/redux';
import { SUBSCRIPTIONS_REDUCER, SUBSCRIPTIONS_RK } from '../modules/Subscriptions/redux';
import { LEFT_SIDEBAR_RK, LEFT_SIDEBAR_REDUCER } from '../components/LeftSidebarContent/redux';
import { DOCUMENT_REDUCER, DOCUMENTS_RK } from '../modules/Documents/redux';
import { MEMBERS_RK, MembersReducer } from '../modules/Members/redux';
import { CUSTOMER_REDUCER, CUSTOMERS_RK } from '../modules/Customers/redux';


export default combineReducers({
	routing: routerReducer,
	form: reduxFormReducer,
	[LEFT_SIDEBAR_RK]: LEFT_SIDEBAR_REDUCER,
	[DOCUMENTS_RK]: DOCUMENT_REDUCER,
	[ARTICLES_RK]: ArticlesReducer,
	[SUBSCRIPTIONS_RK]: SUBSCRIPTIONS_REDUCER,
	[MEMBERS_RK]: MembersReducer,
	[CUSTOMERS_RK]: CUSTOMER_REDUCER,


	user,
	isAuthenticated,
});
