import { fetch } from '../../services/api';
import QueryBuilder from '../../components/WHTable/queryBuilder';
import configApi from '../../constants/ApiConfig';

const endPoints = '/api/users';

export const search = data => {
	const query = QueryBuilder(data);
	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const getCustomers = data => {
	const query = QueryBuilder(data);
	query['roles'] = 'ROLE_USER';
	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const getAdminUsers = data => {
	const query = QueryBuilder(data);
	query['roles'] = 'ROLE_ADMIN';
	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const getDisUsers = data => {
	const query = QueryBuilder(data);
	query['roles'] = 'ROLE_DIS_MAN';
	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = data => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}/account`, 'put', data);

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, 'delete');

export const sendPassword = id => fetch(`${configApi.url}${endPoints}/send-password/${id}`, 'get');

export const anonymize = id => fetch(`${configApi.url}${endPoints}/anonymize/${id}`, 'get');
