export default {
	items: [
		{
			name: 'Souscriptions',
			roles: 'ROLE_ADMIN',
			url: '/subscription',
			icon: 'fas fa--file-signature',
		},
		{
			name: 'Assurés',
			roles: 'ROLE_ADMIN',
			url: '/insureds',
			icon: 'fa fa-address-card-o',
		},
		{
			name: 'Souscripteurs',
			roles: 'ROLE_ADMIN',
			url: '/customers',
			icon: 'fa fa-user',
		},
		{
			name: 'Documents',
			roles: 'ROLE_ADMIN',
			url: '/documents',
			icon: 'fa fa-file',
		},
		{
			name: "Demandes d'annulation",
			roles: 'ROLE_ADMIN',
			url: '/cancellation',
			icon: 'fa fa-ban',
		},
		{
			name: 'CMS',
			roles: 'ROLE_ADMIN',
			icon: 'fa fa-edit',
			children: [
				{
					name: 'Gestion des Pages',
					url: '/pages',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Gestion des Article',
					url: '/posts',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Gestion des Menus',
					url: '/menus',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Gestion des paramètres',
					url: '/parameters',
					roles: 'ROLE_ADMIN',
				},
			],
		},
		{
			name: 'Catalogue',
			roles: 'ROLE_ADMIN',
			icon: 'fa fa-shopping-basket',
			children: [
				{
					name: 'Produits',
					url: '/products',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Articles',
					url: '/articles',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Gestion Tarifs',
					url: '/prices',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Commissions',
					url: '/commissions',
					roles: 'ROLE_ADMIN',
				},
			],
		},
		{
			name: 'Paramètres',
			roles: 'ROLE_ADMIN',
			icon: 'fa fa-cog',
			children: [
				{
					name: 'Configuration',
					url: '/configuration',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Membres',
					url: '/members',
					roles: 'ROLE_ADMIN',
				},
				{
					name: 'Email',
					url: '/mails',
					roles: 'ROLE_ADMIN',
				},
			],
		},
	],
};
