const convertFilters = data => {
	const filters = [];

	const mapObj = { '-': '.', '{': '[', '}': ']' };

	Object.keys(data).map((objectKey, index) => {
		const str = objectKey.replace(/\{|\}|-/gi, matched => mapObj[matched]);

		filters.push({ id: str, value: data[objectKey] });
	});


	return filters;
};

export default convertFilters;
