const APICONFIG = {
	clientId: '1_',
	clientSecret: '1',
	url: 'https://www.chasse-assurances.com',
	nodeServer: 'https://www.chasse-assurances.com',
	assetUrl: 'https://www.chasse-assurances.com',
	helpAdmin: 'https://www.chasse-assurances.com',
	help: 'https://www.chasse-assurances.com',
	finderParams : {
        url : 'https://www.chasse-assurances.com',
        endPointMedia : '/api/media',
        assetUrl : 'https://www.chasse-assurances.com',
    }
};

module.exports = APICONFIG;
