export const STATUS = 'Etat/statut';
export const ALL = 'Tous';
export const VALID = 'Valide';
export const INVALID = 'Non valide';
export const SENT = 'Envoyé';
export const QUOTE = 'En attente de validation';
export const TREATMENT_PENDING = 'En attente de paiement';
export const UNPAYED = 'Impayé';
export const UPDATED = 'Modifié';
export const ARCHIVED = 'Archivé';


export const CANCELLED = 'Annulé';
export const CANCELLATION_ASKED = 'Annuation demandée';
export const AMENDMENT = 'Modifications en cours';


export const ALL_VALUE = "";
export const QUOTE_VALUE = '1';
export const AMENDMENT_VALUE = '2';
export const VALID_VALUE = '100';
export const CANCELLATION_ASKED_VALUE = '200';
export const CANCELLED_VALUE = '300';
export const INVALID_VALUE = '400';
export const PENDING_PAYMENT_VALUE = '500';
export const UNPAYED_VALUE = '600';
export const UPDATED_VALUE = '700';
export const ARCHIVED_VALUE = '800';

export const ALL_CLASSNAME = 'all';
export const QUOTE_CLASSNAME = 'pending';
export const AMENDMENT_CLASSNAME = 'modifications';
export const VALID_CLASSNAME = 'valid';
export const CANCELLATION_ASKED_CLASSNAME = 'cancellation-asked';
export const CANCELLED_CLASSNAME = 'cancelled';
export const INVALID_CLASSNAME = 'invalid';
export const PENDING_PAYMENT_CLASSNAME = 'treatment-pending';
export const UNPAYED_CLASSNAME = 'unpayed';
export const UPDATED_CLASSNAME = 'updated';
export const ARCHIVED_CLASSNAME = 'archived';

export const statusTypes = [
	{
		name: ALL,
		value: ALL_VALUE,
		className: ALL_CLASSNAME,
	},
	{
		name: QUOTE,
		value: QUOTE_VALUE,
		className: QUOTE_CLASSNAME,
	},
	{
		name: AMENDMENT,
		value: AMENDMENT_VALUE,
		className: AMENDMENT_CLASSNAME,
	},
	{
		name: VALID,
		value: VALID_VALUE,
		className: VALID_CLASSNAME,
	},
	{
		name: CANCELLATION_ASKED,
		value: CANCELLATION_ASKED_VALUE,
		className: CANCELLATION_ASKED_CLASSNAME,
	},
	{
		name: CANCELLED,
		value: CANCELLED_VALUE,
		className: CANCELLED_CLASSNAME,
	},
	{
		name: INVALID,
		value: INVALID_VALUE,
		className: INVALID_CLASSNAME,
	},

	{
		name: TREATMENT_PENDING,
		value: PENDING_PAYMENT_VALUE,
		className: PENDING_PAYMENT_CLASSNAME,
	},
	{
		name: UNPAYED,
		value: UNPAYED_VALUE,
		className: UNPAYED_CLASSNAME,
	},
	{
		name: UPDATED,
		value: UPDATED_VALUE,
		className: UPDATED_CLASSNAME,
	},
	{
		name: ARCHIVED,
		value: ARCHIVED_VALUE,
		className: ARCHIVED_CLASSNAME,
	},
];

export const statusValueToClassNameMapper = {
	[ALL_VALUE]: ALL_CLASSNAME,
	[QUOTE_VALUE]: QUOTE_CLASSNAME,
	[AMENDMENT_VALUE]: AMENDMENT_CLASSNAME,
	[VALID_VALUE]: VALID_CLASSNAME,
	[CANCELLATION_ASKED_VALUE]: CANCELLATION_ASKED_CLASSNAME,
	[CANCELLED_VALUE]:CANCELLED_CLASSNAME,
	[INVALID_VALUE]: INVALID_CLASSNAME,
	[PENDING_PAYMENT_VALUE]: PENDING_PAYMENT_CLASSNAME,
	[UNPAYED_VALUE]: UNPAYED_CLASSNAME,
	[UPDATED_VALUE]: UPDATED_CLASSNAME,
	[ARCHIVED_VALUE]: ARCHIVED_CLASSNAME,
};

export const statusValueToLabel = {
	[QUOTE_VALUE]: QUOTE,
	[AMENDMENT_VALUE]: AMENDMENT,
	[VALID_VALUE]: VALID,
	[CANCELLATION_ASKED_VALUE]: CANCELLATION_ASKED,
	[CANCELLED_VALUE]:CANCELLED,
	[INVALID_VALUE]: INVALID,
	[PENDING_PAYMENT_VALUE]: TREATMENT_PENDING,
	[UNPAYED_VALUE]: UNPAYED,
	[UPDATED_VALUE]: UPDATED,
	[ARCHIVED_VALUE]: ARCHIVED,
};

export const paymentTypes = [
	{
		name: 'Aucun paiement nécessaire',
		value: 0,
	},
	{
		name: 'Carte de crédit',
		value: 10,
	},
	{
		name: 'Chèque',
		value: 20,
	},
	{
		name: 'Espèces',
		value: 30,
	},
	{
		name: 'Mandat',
		value: 40,
	},
	{
		name: 'Virement',
		value: 50,
	},
];

export const civilityTypes = [
	{
		name: 'Tous',
		value: "",
	},
	{
		name: 'M.',
		value: 'm',
	},
	{
		name: 'Mme',
		value: 'mme',
	},
];

export const YesNoTypes = [
	{
		name: 'Tous',
		value: "",
	},
	{
		name: 'Oui',
		value: true,
	},
	{
		name: 'Non',
		value: false,
	},
];

export const exportTypes = [
	{
		name: 'Emails',
		value: 6,
	},
	{
		name: 'Adresses',
		value: 1,
	},
];
export const countryTypes = [
	{
		value: 'Fr',
		label: 'France ',
	},
	{
		value: 'Be',
		label: 'Belgique ',
	},
];

export const affiliatedTypes = [
	{
		name: 'Tous',
		value: "",
	},
	{
		name: 'ADH Web',
		value: 10,
	},
	{
		name: 'ADH Guichet',
		value: 20,
	},
];

export const labelSize = { labelSize: 5 };

export const propertyToLabelMapper = {
	dbId: 'Id en DB',
	clientId: 'ID client',
	email: 'Email',
	name: 'Nom',
	phone: 'Téléphone',
	zipCode: 'Code Postal',
	dateFirstInferior: 'Date de 1ère souscription <=',
	dateFirstSuperior: 'Date de 1ère souscription >=',
	dateRevisionInferior: 'Date de révision <=',
	dateRevisionSuperior: 'Date de révision >=',
	paymentType: 'Type paiment',
	adhesionNumber: 'n° adhésion',
	affiliatedUser: 'utilisateur affilié',
	status: 'Etat/Status',
};
