import React from 'react';
import CONFIG from '../../constants/parameters';

const Footer = () => (
    <footer className="app-footer">
        <span>
            <a href={CONFIG.author.url}>{CONFIG.author.name}</a> &copy;  2019 &copy;
             {CONFIG.author.name}.
        </span>
        <span className="ml-auto">
            Made with <i className="fa fa-heart" /> by{' '}
            <a href={CONFIG.author.url}>{CONFIG.author.name}</a>
        </span>
    </footer>
);

export default Footer;
