import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { FieldTxt, FieldSelect, FieldDate } from '@whatsonweb/fields';
import { href } from 'react-friends';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSubscriptions, setFilters,setActiveFilters } from '../redux/';
import convertFilters from '../../../utils/convertFiltersNew';
import {
	getYearsFromSubscriptions,
	convertItemAsValuesCollectionByMappingProperties,
	formatData,
} from './utils';
import {
	affiliatedTypes,
	paymentTypes,
	statusTypes,
	labelSize,
	propertyToLabelMapper,
} from './constants';
import './filters.scss';
import './../icon.scss';

class SubscriptionFilter extends Component {
	submit = evt => {
		evt.preventDefault();
		const labels = this.props.currentValues ? convertItemAsValuesCollectionByMappingProperties(this.props.currentValues, propertyToLabelMapper) : [];
		this.props.setActiveFilters(labels);

		//this.props.onSubmit(prepareFiltersToBeParsedAsQueryParams(this.props.currentValues || {}));
		this.props.setFilters(this.props.currentValues ? convertFilters(this.props.currentValues) : []);

		this.props.onClose();
	};


	render() {
		const { reset, change, subscriptions } = this.props;
		const { submit } = this;
		return (
			<form className="form-horizontal filters">
				<div className="buttonsContainer">
					<button type="button" className="btn btn-outline-success" onClick={submit}>
							<i className="fa fa-check" />
							Valider
					</button>
						<button type="button" className="btn btn-outline-danger" onClick={reset}>
							<i className="fa fa-times" />
							Reset
					</button>
				</div>
	
				<div className="flags-header">
					{statusTypes.map(({ value, name, className }) => (
						<a {...href} key={value}>
							<i
								className={`fa fa-flag fa-2x icon-${className}`}
								onClick={() => change('status', value == 0 ? "" :Number(value))}
								title={name}
							/>
						</a>
					))}
				</div>
				<Field
					label="Date 1ère souscription >="
					name="startDate[after]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Date 1ère souscription <="
					name="startDate[before]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Année"
					name="startDate"
					component={FieldSelect}
					{...labelSize}
					options={getYearsFromSubscriptions(subscriptions || [])}
				/>
				<Field
					label="Date révision/création >="
					name="created[after]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Date révision/création <="
					name="created[before]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Num. adhésion :"
					name="membershipNumber"
					component={FieldTxt}
					{...labelSize}
				/>
				<Field name="id" component={FieldTxt} label="ID db :" {...labelSize} />
				<Field name="insured-id" component={FieldTxt} label="ID client :" {...labelSize} />
				<Field name="insured-email" component={FieldTxt} label="Email :" {...labelSize} />
				<Field name='insured-phone' component={FieldTxt} label="Tél. :" {...labelSize} />
				<Field name="insured-lastName" component={FieldTxt} label="Nom :" {...labelSize} />
				<Field name="insured-firstName" component={FieldTxt} label="Prénom :" {...labelSize} />
				<Field name="insured-zipCode" component={FieldTxt} label="Code Postal :" {...labelSize} />
				<Field
					label="Etat/statut"
					name="status"
					options={statusTypes}
					component={FieldSelect}
					{...labelSize}
				/>
				<Field
					label="Utilisateur affilié"
					name="userType"
					component={FieldSelect}
					options={affiliatedTypes}
					{...labelSize}
				/>
				<Field
					label="Mode de paiement"
					name="paymentType"
					component={FieldSelect}
					options={paymentTypes}
					{...labelSize}
				/>
			
			</form>
		);
	}
}

SubscriptionFilter.defaultProps = {
	subscriptions: [],
};

SubscriptionFilter.propTypes = {
	reset: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	onSetActiveFilters: PropTypes.func.isRequired,
	subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const formName = 'subscriptionFilter';
export const mapStateToProps = state => ({



	currentValues: getFormValues(formName)(state),
	subscriptions: getSubscriptions(state),
});

export const mapDispatchToProps = dispatch => bindActionCreators({ setActiveFilters,setFilters }, dispatch);

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReduxForm = reduxForm({ form: formName });

export default compose(withConnect, withReduxForm)(SubscriptionFilter);
