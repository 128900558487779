import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import { NullLoadable } from 'react-friends';

import Full from './containers/Full/';

import './styles';

const Login = NullLoadable(() => import('./modules/Pages/Login'));
const Register = NullLoadable(() => import('./modules/Pages/Register'));
const Page404 = NullLoadable(() => import('./modules/Pages/Page404'));
const Page500 = NullLoadable(() => import('./modules/Pages/Page500'));

ReactDOM.render(<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route exact path="/login" name="Login Page" component={Login} />
				<Route exact path="/register" name="Register Page" component={Register} />
				<Route exact path="/404" name="Page 404" component={Page404} />
				<Route exact path="/500" name="Page 500" component={Page500} />
				<Route path="/" name="Home" component={Full} />
			</Switch>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'));
